import {
  type FunctionComponent,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import { useRouter } from 'next/router';
import { Box, IconButton } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import { ChevronLeft } from 'components/Icons/ChevronLeft';
import { ChevronRight } from 'components/Icons/ChevronRight';
import SpotPreviewCard from 'components/SpotPreviewCard';
import {
  useMaxWidthTablet,
  useMinWidthTablet,
  useMinWidthDesktop,
  useMinWidthDesktopLarge,
} from 'hooks/useMediaQueries';
import type { Units } from 'types/units';
import type { UserFavorite, UserFavorites } from 'types/userFavorites';
import { spotReportPath } from 'utils/urls';

import styles from './FavoritesCarouselSnap.module.scss';

interface FavoritesCarouselSnapProps {
  favorites: UserFavorites;
  isActive?: boolean;
  units: Units;
}

const Slide: FunctionComponent<{
  favorite: UserFavorite;
  href: string;
  index: number;
  isActive: boolean;
  isMaxWidthTablet: boolean;
  snapPointIndexes: Set<number>;
  units: Units;
}> = ({ favorite, href, index, isActive, isMaxWidthTablet, snapPointIndexes, units }) => {
  const { asPath, push: routerPush } = useRouter();
  const camera = favorite?.cameras.filter((cam) => !cam.status.isDown)?.[0];

  const listItemStyle = useMemo(
    () => ({
      scrollSnapAlign: snapPointIndexes?.has(index) ? 'start' : 'none',
    }),
    [index, snapPointIndexes],
  );

  const geo = useMemo(
    () => ({ lat: favorite.lat, lon: favorite.lon }),
    [favorite.lat, favorite.lon],
  );

  const currentWaveHeight = useMemo(
    () => ({
      forecaster: favorite.waveHeight.forecaster,
      human: favorite.waveHeight.human,
      max: favorite.waveHeight.max,
      min: favorite.waveHeight.min,
      plus: favorite.waveHeight.plus,
      lastObserved: favorite.waveHeight?.lastObserved,
      type: favorite.waveHeight?.type,
    }),
    [favorite.waveHeight],
  );

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      trackEvent('Carousel Clicked', {
        carouselItem: favorite.name,
        carouselName: 'Favorites Carousel',
        category: 'kbyg',
        path: asPath,
        postId: favorite._id,
        positionNumber: index,
        title: 'Home',
      });
      if (!(e.target as HTMLElement).closest('a')) {
        e.preventDefault();
        routerPush(href);
      }
    },
    [favorite, asPath, index, routerPush, href],
  );

  return (
    <li
      className={styles.item}
      data-testid="favorites-carousel-snap-card"
      key={favorite._id}
      style={listItemStyle}
    >
      <SpotPreviewCard
        alwaysDisplaySmall={false}
        camera={camera}
        cardIndex={index}
        currentConditions={favorite.conditions.value}
        currentWaveHeight={currentWaveHeight}
        currentWind={favorite.wind}
        geo={geo}
        hideThumbnailOnMobile={false}
        id={favorite._id}
        insightsCameraId={favorite.insightsCameraId}
        isMobileView={isMaxWidthTablet}
        sideThumbnail={false}
        stopStream={!isActive}
        title={favorite.name}
        units={units}
        onClickHandler={handleOnClick}
        href={href}
        timezone={favorite.timezone}
        useClarityComponents
      />
    </li>
  );
};

const FavoritesCarouselSnap: FunctionComponent<FavoritesCarouselSnapProps> = ({
  favorites,
  isActive: isCarouselActive = true,
  units,
}) => {
  const isMaxWidthTablet = useMaxWidthTablet();
  const {
    activePageIndex,
    hasNextPage,
    hasPrevPage,
    next,
    pages,
    prev,
    refresh,
    scrollRef,
    snapPointIndexes,
  } = useSnapCarousel(favorites.length ? { initialPages: [[0]] } : undefined);

  const isMinWidthTablet = useMinWidthTablet();
  const isMinWidthDesktop = useMinWidthDesktop();
  const isMinWidthDesktopLarge = useMinWidthDesktopLarge();

  const onClickPrevious = useCallback(() => {
    prev();
  }, [prev]);

  const onClickNext = useCallback(() => {
    next();
  }, [next]);

  const slidesPerPage = useMemo(() => {
    if (isMinWidthDesktopLarge) return 4;
    if (isMinWidthDesktop) return 3;
    if (isMinWidthTablet) return 2;
    return 1;
  }, [isMinWidthDesktop, isMinWidthDesktopLarge, isMinWidthTablet]);

  const slides = useMemo(
    () =>
      favorites.map((favorite, index) => {
        const spotUrl = spotReportPath({}, { _id: favorite._id, name: favorite.name });
        const isLastPage = activePageIndex === pages.length - 1;
        const startIndex = isLastPage
          ? favorites.length - slidesPerPage
          : activePageIndex * slidesPerPage;
        const endIndex = Math.min(startIndex + slidesPerPage, favorites.length);
        const isSlideActive = isCarouselActive && index >= startIndex && index < endIndex;

        return (
          <Slide
            favorite={favorite}
            href={spotUrl}
            index={index}
            isActive={isSlideActive}
            isMaxWidthTablet={isMaxWidthTablet}
            key={favorite._id}
            snapPointIndexes={snapPointIndexes}
            units={units}
          />
        );
      }),
    [
      favorites,
      activePageIndex,
      pages.length,
      slidesPerPage,
      isCarouselActive,
      isMaxWidthTablet,
      snapPointIndexes,
      units,
    ],
  );

  const prevSlidesLength = useRef(slides.length);

  useEffect(() => {
    if (slides.length !== prevSlidesLength.current) {
      refresh();
      prevSlidesLength.current = slides.length;
    }
  }, [slides, refresh]);

  if (!favorites.length) return null;

  return (
    <Box className="sl-section-container" component="section" data-testid="favorites-carousel-snap">
      <div className={styles.actions}>
        <IconButton
          className={styles.arrow}
          data-testid="favorites-carousel-snap-previous"
          disabled={!hasPrevPage}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={onClickPrevious}
          size="small"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          className={styles.arrow}
          data-testid="favorites-carousel-snap-next"
          disabled={!hasNextPage}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={onClickNext}
          size="small"
        >
          <ChevronRight />
        </IconButton>
      </div>
      <ul className={styles.scrollContainer} ref={scrollRef}>
        {slides}
      </ul>
    </Box>
  );
};

export default FavoritesCarouselSnap;
